//layout

//======================  pink pallet  ====================== //
//:root {
//    --primaryBgColor: #DF5286;
//    --primaryBgColorDark: #DF5286;
//    --primaryBgColorLight: #E0115F;
//    --primaryFgColor: #fff;
//
//    --secondaryBgColor: #fff;
//    --secondaryFgColor: #DE3163;
//    --secondaryFgColorLight: #fff;
//
//    // splash screen
//    --splashScreenBgColor: #fff;
//    --splashScreenFgColor: #DE3163;
//
//    // toolbar-system
//    --toolbarSystemFgColor: #fff;
//    --toolbarSystemBgColor: #DE3163;
//}

//======================  dark green pallet  ====================== //
//:root {
//    --primaryBgColor: #1e453e;
//    --primaryBgColorDark: #306844;
//    --primaryBgColorLight: #2c4c3b;
//    --primaryFgColor: #fff;
//
//    --secondaryBgColor: #fff;
//    --secondaryFgColor: #455b55;
//    --secondaryFgColorLight: #fff;
//
//    // splash screen
//    --splashScreenBgColor: #040a06;
//    --splashScreenFgColor: #97b3a1;
//
//    // toolbar-system
//    --toolbarSystemFgColor: #040a06;
//    --toolbarSystemBgColor: #97b3a1;
//}

//======================  dark blue pallet  ====================== //
:root {
    --primaryBgColor: #0E2A3B;
    --primaryBgColorDark: #2D323E;
    --primaryBgColorLight: #0E2A3B;
    --primaryFgColor: #fff;

    --secondaryBgColor: #03a9f4;
    --secondaryFgColor: #fff;
    --secondaryFgColorLight: #fff;

    // splash screen
    --splashScreenBgColor: #3C4252;

    // toolbar-system
    --toolbarSystemFgColor: #fff;
    --toolbarSystemBgColor: #3c4252;

    // Bgs
    --secondaryBgImage: 'url(/assets/images/backgrounds/dark-material-bg.jpg)';
}

#fuse-splash-screen {
    background-color: var(--splashScreenBgColor);

    .spinner-wrapper .spinner .inner .left .half-circle,
    .spinner-wrapper .spinner .inner .right .half-circle {
        border: 10px solid var(--secondaryFgColor);
    }
}

navbar {
    &.mat-fuse-dark-700-bg {
        background-color: var(--primaryBgColorDark) !important;
    }


    .navbar-vertical .navbar-header {
        background: var(--primaryBgColorLight) !important;
        color: var(--primaryFgColor) !important;
    }
}

.nav .nav-item .nav-link.mat-accent-bg,
.mat-flat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-fab.mat-accent, .mat-mini-fab.mat-accent {
    background-color: var(--secondaryBgColor) !important;
    color: var(--secondaryFgColor) !important;
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
    background-color: var(--primaryBgColor) !important;
    color: var(--primaryFgColor) !important;
}

#home, #error-page, sip-auth-home-sessao {
    background: var(--primaryBgColorLight) !important;

    .content-home {
        color: var(--primaryFgColor);
    }
}

.header {
    background: var(--primaryBgColorLight) !important;
}

.mat-toolbar.mat-primary {
    background: var(--toolbarSystemBgColor);
    color: var(--toolbarSystemFgColor);
}

.page-layout.carded > .top-bg.mat-accent-bg {
    background-color: var(--primaryBgColorLight) !important;
    color: var(--secondaryFgColorLight) !important;
}

#coming-soon {
    background: var(--secondaryBgImage) no-repeat !important;
}